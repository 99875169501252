import React, { FunctionComponent } from "react";
import Container from "react-bootstrap/Container";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { ColorVariations } from "web-common/src/types/ComponentTypes";
import { ImageInterface, MultiLineHeadline, PortableText, SanityColorList } from "web-common/src/types/SanityTypes";
import "./styles.scss";

export interface IframeSnippetPromotionInterface {
  _rawImage: ImageInterface;
  headline?: MultiLineHeadline;
  bgColor: SanityColorList;
  _rawIntroduction?: PortableText;
  ctaLabel: string;
  iframeSnippetValue: string;
  image?: ImageInterface;
  introduction?: PortableText;
}

const IframeSnippetPromotion: FunctionComponent<IframeSnippetPromotionInterface> = ({
  _rawImage,
  image,
  headline,
  bgColor,
  _rawIntroduction,
  introduction,
  iframeSnippetValue,
}) => {
  const iframeImage = _rawImage || image;
  const iframeIntro = _rawIntroduction || introduction;
  const showHeader = iframeImage && headline ? true : false;

  const bigUrl = iframeImage?.asset?.url + "?fm=webp";

  const colorVariations: ColorVariations = {
    Blue: {
      primaryGradientColor: "var(--blue-dark-color)",
      secondaryGradientColor: "var(--blue-bright-color)",
      gradientDegrees: "90"
    },
    Pink: {
      primaryGradientColor: "var(--pink-color)",
      secondaryGradientColor: "var(--pink-color)",
      gradientDegrees: "90"
    }
  };
  const renderGradient = `linear-gradient(${colorVariations[bgColor.title].gradientDegrees}deg, ${colorVariations[bgColor.title].primaryGradientColor
    } 0%, ${colorVariations[bgColor.title].secondaryGradientColor} 94%)`;


  const iframeTitle = headline?.primaryText + " " + (headline?.secondaryText || "") + " " + (headline?.tertiaryText || "");

  const renderAnchorId = iframeTitle.replace(/ /g, "-");

  const renderContent = (sectionClasses: string, textClasses: string, linkClasses: string) => {

    return (
      <>
      {showHeader && (
        <section className="iframe-snippet" style={{ background: renderGradient, color: "white" }}>
          <Container fluid>
            <div className="row no-gutters">
              <div className="col-12">
                <h2 id="header1">
                  {headline?.primaryText}
                  {headline?.secondaryText ? <span id="header2">{headline?.secondaryText}</span> : null}
                  {headline?.tertiaryText ? <span id="header3">{headline?.tertiaryText}</span> : null}
                </h2>
              </div>
              {iframeIntro ? (
                <div className={textClasses}>
                  <div className="body-copy" id="promoBlockCopy">
                    <BlockContent blocks={iframeIntro} />
                  </div>
                </div>
              ) : null}
            </div>
          </Container>
        </section>
      )}
        <section>
          <Container className={"iframe-visible"} id={renderAnchorId} fluid style={{ height: "800px" }} dangerouslySetInnerHTML={{ __html: iframeSnippetValue }} />
        </section>
      </>
    );
  };

  return (
    <>
      <style>
        {`
          .content-background {
            background: linear-gradient(90deg, #221b94 0%, #4238fa 94%);
          }
          @media (min-width: 768px) {
            .iframe-snippet .container-fluid {
              background: url(${bigUrl}) no-repeat;
              background-size: contain;
              background-position: 300px;
            }
          }
          @media (min-width: 992px) {
            .content-background .container-fluid {
              background-position: 98%;
            }
          }
        `}
      </style>
      {renderContent(
        "iframe-promotion-block content-background",
        "col-12 col-md-7 offset-lg-1",
        "col-12 col-md-5 col-lg-4 ball"
      )}
    </>
  );
};

export default IframeSnippetPromotion;
